
import './Home.css'; // Import the CSS file

const Kostas = () => {
  // Define a state variable 'count' with an initial value of 0
  

  // Function to handle the click event and update the count
  

  return (
    <div className="app">
   <h1 style={{scale: '200%',marginTop: '30vh',}}>UNDER CONSTRUCTION</h1>
    </div>
  );
};

export default Kostas;
