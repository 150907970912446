
import './Home.css'; // Import the CSS file

const Home = () => {
  // Define a state variable 'count' with an initial value of 0
  

  // Function to handle the click event and update the count
  

  return (
    <div className="app">
        <div className="MainBodyContainer">
            <h1 style={{scale: '200%',color: 'white',marginTop: 100}}>WORKS</h1>

        </div>
   
    </div>
  );
};

export default Home;
